import styles from "./Download.module.css";
import image from "../Images/Portuguese/3.png";

export default function Download() {
    return (
        <section id="download">
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.image}>
                        <div className={styles.circle} />
                        <img src={image} alt="Storyland" />
                    </div>
                    <div className={styles.text}>
                        <span className={styles.title}>
                            Faça download agora e comece a criar histórias incríveis!
                        </span>
                        <span className={styles.subtitle}>
                            O aplicativo é totalmente gratuito e está disponível para Android, estamos desenvolvendo a versão de IOS, fique ligado! Baixe agora e comece a criar histórias incríveis para seus filhos!
                        </span>
                        <button className={styles.button}><a href="https://play.google.com/store/apps/details?id=com.tosostudio.eraumavez" target="_blank">Baixar agora</a></button>
                    </div>
                </div>
            </div>
        </section>
    );
}