import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./LandingPage/LandingPage";
import EraUmaVez from "./Pages/EraUmaVez";
import BrainBuzz from "./Pages/BrainBuzz";
import Grimorio from "./Pages/Grimorio";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAz16YA0UlRZsrmjr_JFZxmzm7HmanAgPM",
  authDomain: "eraumavez-91ed2.firebaseapp.com",
  projectId: "eraumavez-91ed2",
  storageBucket: "eraumavez-91ed2.firebasestorage.app",
  messagingSenderId: "1056386522247",
  appId: "1:1056386522247:web:83f359d171911d99ef6c22",
  measurementId: "G-ZYMZ2BF66G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LandingPage />} path="/" />
        <Route element={<EraUmaVez />} path="/eraumavez" />
        <Route element={<BrainBuzz />} path="/brainbuzz" />
        <Route element={<Grimorio />} path="/grimorio" />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
