import styles from "./Footer.module.css";
import Youtube from "../Images/youtube.svg";
import Instagram from "../Images/instagram.svg";
import Facebook from "../Images/facebook.svg";

export default function Footer(props) {

    const { setSelection } = props;

    return (
        <section>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.panel}>
                        <span className={styles.titulo}>Aplicativo Storyland</span>
                        <span>2025 - Todos os direitos reservados</span>
                        <div className={styles.socials}>
                            {/* <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                <img src={Facebook} alt="Facebook" />
                            </a>
                            <a href="https://www.instagram.com/EraUmaVezApp" target="_blank" rel="noreferrer">
                                <img src={Instagram} alt="Instagram" />
                            </a> */}
                            <a href="https://www.youtube.com/@EraUmaVezApp" target="_blank" rel="noreferrer">
                                <img src={Youtube} alt="Youtube" />
                            </a>
                        </div>
                    </div>
                    <div className={styles.panel}>
                        <span className={styles.titulo}>Links Rápidos</span>
                        <span className={styles.link} onClick={() => setSelection("home")}>Inicio</span>
                        <span className={styles.link} onClick={() => setSelection("about")}>Quem somos</span>
                        <span className={styles.link} onClick={() => setSelection("features")}>Funcionalidades</span>
                        <span className={styles.link} onClick={() => setSelection("download")}>Download</span>
                    </div>
                    <div className={styles.panel}>
                        <span className={styles.titulo}>Termos e Condições</span>
                        <span className={styles.link}><a href="https://tosostudio.com.br/eraumavez" target="_blank">Termo de Uso</a></span>
                        <span className={styles.link}><a href="https://tosostudio.com.br/eraumavez" target="_blank">Politica de Privacidade</a></span>
                    </div>
                    <div className={styles.panel}>
                        <span className={styles.titulo}>Contato e Suporte</span>
                        <span className={styles.link}>contato@tosostudio.com.br</span>
                        <span className={styles.link}>www.tosostudio.com.br</span>
                        <span className={styles.link}>Curitiba, Brazil</span>
                    </div>
                </div>
            </div>
        </section>
    );
}