import styles from "./Why.module.css";

export default function Why() {
    return (
        <section id="about">
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.text}>
                        <span className={styles.title}>
                            Por que usar o Storyland?
                        </span>
                        <span className={styles.subtitle}>
                            Descubra nossas histórias incríveis que despertam a curiosidade e o imaginação, tornando a hora de dormir o momento mais esperado do dia.
                        </span>
                    </div>
                    <div className={styles.cards}>
                        <div className={styles.card}>
                            <div className={styles.icon} style={{ backgroundColor: "#e7a019" }}>
                                <span className="material-symbols-rounded">auto_stories</span>
                            </div>
                            <span className={styles.cardTitle}>Histórias exclusivas</span>
                            <span className={styles.cardSubTitle}>
                                Todas as histórias são geradas por IA, garantindo exclusividade e temas envolventes que estimulam a imaginação das crianças.
                            </span>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.icon} style={{ backgroundColor: "#e54f4f" }}>
                                <span className="material-symbols-rounded">trending_up</span>
                            </div>
                            <span className={styles.cardTitle}>Desenvolvimento infantil</span>
                            <span className={styles.cardSubTitle}>
                                Projetado para desenvolver linguagem, compreensão, valores e inteligência emocional em crianças de todas as idades.
                            </span>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.icon} style={{ backgroundColor: "#8950e4" }}>
                                <span className="material-symbols-rounded">smartphone</span>
                            </div>
                            <span className={styles.cardTitle}>Interface amigável</span>
                            <span className={styles.cardSubTitle}>
                                Navegação simples e prática, permitindo uma experiência agradável e intuitiva para momentos em família.
                            </span>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.icon} style={{ backgroundColor: "#0898e7" }}>
                                <span className="material-symbols-rounded">bedtime</span>
                            </div>
                            <span className={styles.cardTitle}>Rotina de leitura</span>
                            <span className={styles.cardSubTitle}>
                                Incentiva o hábito da leitura em momentos como antes de dormir, fortalecendo o vínculo entre pais e filhos.
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}