import styles from "./Principal.module.css";
import imagem from "../Images/Portuguese/1.png";

export default function Principal() {
    return (
        <section id="home">
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.text}>
                        <span className={styles.title}>
                            <span className={styles.hightlight}>O melhor aplicativo</span> de histórias infantis.
                        </span>
                        <span className={styles.subtitle}>
                            Ideal para pais e filhos, ler uma história infantil para seu pequeno é uma forma adorável de terminar o dia, garantido um sonho mais tranquilo e repreto de imaginação.
                        </span>
                        <button className={styles.button}><a href="https://play.google.com/store/apps/details?id=com.tosostudio.eraumavez" target="_blank">Baixar agora</a></button>
                    </div>
                    <div className={styles.image}>
                        <img src={imagem} alt="aplicativo Storyland" />
                    </div>
                </div>
            </div>
        </section>
    );
}