import React, { useEffect, useState } from "react";
import Header from "./Header";
import Principal from "./Principal";
import Why from "./Why";
import Feature from "./Feature";
import Download from "./Download";
import Footer from "./Footer";

import styles from "./LandingPage.module.css";
import Keys from "./Keys";

const LandingPage = () => {

    const [selection, setSelection] = useState("home");

    //role a pagina ate o componente
    const scrollTo = (id) => {
        const element = document.getElementById(id);

        if (!element)
            return;

        element?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollTo(selection);
    }, [selection]);

    return (
        <div className={styles.container}>
            <Header selection={selection} setSelection={setSelection} />
            <Principal />
            <Why />
            <Feature />
            <Keys />
            <Download />
            <Footer setSelection={setSelection} />
        </div>
    );
};

export default LandingPage;
