import styles from "./Feature.module.css";
import image from "../Images/Portuguese/2.png";

export default function Feature() {
    return (
        <section id="features">
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.image}>
                        <div className={styles.circle} />
                        <img src={image} alt="Storyland" />
                    </div>
                    <div className={styles.text}>
                        <span className={styles.title}>
                            Transforme cada história em uma experiência única!
                        </span>
                        <span className={styles.subtitle}>
                            Nossa IA não apenas gera histórias exclusivas, mas também transforma o momento da leitura em um hábito que fortalece o vínculo entre pais e filhos. Imagine criar uma narrativa única para cada noite, explorando mundos incríveis e ensinando valores importantes enquanto estimula a criatividade e o aprendizado infantil.
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
}