import styles from "./Header.module.css";
import image from "../Images/icon.png";

export default function Header(props) {

    const { selection = "home", setSelection } = props;

    return (
        <section className={styles.container}>
            <div className={styles.logo}>
                <img src={image} alt="logo" />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.menu} style={{ color: selection === "home" ? "#ED7690" : "#38424D" }} onClick={() => setSelection("home")}>
                    <span>Inicio</span>
                </div>
                <div className={styles.menu} style={{ color: selection === "about" ? "#ED7690" : "#38424D" }} onClick={() => setSelection("about")}>
                    <span>Quem somos</span>
                </div>
                <div className={styles.menu} style={{ color: selection === "features" ? "#ED7690" : "#38424D" }} onClick={() => setSelection("features")}>
                    <span>Funcionalidades</span>
                </div>
                <div className={styles.menu} style={{ color: selection === "download" ? "#ED7690" : "#38424D" }} onClick={() => setSelection("download")}>
                    <span>Download</span>
                </div>
            </div>
        </section>
    );
}