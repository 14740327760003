import styles from "./Keys.module.css";
import image from "../Images/Portuguese/6.png";

export default function Keys() {
    return (
        <section>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.text}>
                        <span className={styles.title}>
                            Tudo isso dentro de um só aplicativo!
                        </span>
                        <span className={styles.subtitle}>
                            Criamos pensando em você e na sua família, para que a hora de dormir seja um momento especial e único.
                        </span>
                    </div>
                    <div className={styles.features}>
                        <div className={styles.cards}>
                            <div className={styles.card}>
                                <div className={styles.icon} style={{ backgroundColor: "#e7a019" }}>
                                    <span className="material-symbols-rounded">auto_stories</span>
                                </div>
                                <div className={styles.cardInfos}>
                                    <span className={styles.cardTitle}>Dezenas de histórias exclusivas</span>
                                    <span className={styles.cardSubTitle}>
                                        Explore um acervo vasto de histórias únicas, criadas especialmente para estimular a imaginação das crianças. Cada narrativa é cuidadosamente desenvolvida para proporcionar momentos mágicos e inesquecíveis em família.
                                    </span>
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles.icon} style={{ backgroundColor: "#e54f4f" }}>
                                    <span className="material-symbols-rounded">lightbulb</span>
                                </div>
                                <div className={styles.cardInfos}>
                                    <span className={styles.cardTitle}>Crie suas histórias personalizadas</span>
                                    <span className={styles.cardSubTitle}>
                                        Dê asas à imaginação! Nossa IA permite que você personalize cada detalhe das histórias, desde os temas até os cenários, criando aventuras únicas que se adaptam ao gosto e às preferências do seu filho.                                    </span>
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles.icon} style={{ backgroundColor: "#8950e4" }}>
                                    <span className="material-symbols-rounded">money_off</span>
                                </div>
                                <div className={styles.cardInfos}>
                                    <span className={styles.cardTitle}>Gratuito para sempre</span>
                                    <span className={styles.cardSubTitle}>
                                        Desfrute de todas as funcionalidades do app sem custo algum! Acreditamos que momentos especiais em família devem ser acessíveis a todos, sem barreiras. Baixe agora e aproveite!
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.image}>
                            <div className={styles.circle} />
                            <img src={image} alt="Storyland" />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}