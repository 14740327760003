import styles from './Termos.module.css';
import Picture from './../Images/eraumavez.png'

export default function EraUmaVez() {

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <img className={styles.picture} src={Picture} alt="Storyland" />
                <div className={styles.title}>Storyland</div>
                <div className={styles.content}>
                    <div className={styles.topic}>Termos e Condições - Storyland</div>
                    <div className={styles.text}>Última atualização: 22/05/2023</div>
                    <div className={styles.text}>Bem-vindo(a) ao "Storyland". Ao utilizar nosso aplicativo e/ou acessar nosso site, você concorda em cumprir os seguintes termos e condições ("Termos") e nossa Política de Privacidade. Certifique-se de ler atentamente esses Termos antes de utilizar o aplicativo ou o site.</div>
                    <div className={styles.topic}>1. Uso do Aplicativo.</div>
                    <div className={styles.text}>1.1. O "Storyland" é um aplicativo destinado a oferecer contos mágicos e educacionais para crianças de todas as idades. Ao utilizar o aplicativo, você concorda em respeitar todas as políticas e diretrizes relacionadas à faixa etária apropriada e ao conteúdo adequado para todas as idades.</div>
                    <div className={styles.text}>1.2. Você é responsável por qualquer informação fornecida durante o uso do aplicativo. Certifique-se de que todas as informações fornecidas sejam verdadeiras, precisas e atualizadas.</div>
                    <div className={styles.text}>1.3. Os contos, imagens, animações e outros recursos disponíveis no aplicativo são protegidos por direitos autorais e são de propriedade do "Storyland" ou de terceiros licenciados. Você concorda em não copiar, reproduzir, distribuir ou criar trabalhos derivados do conteúdo sem autorização prévia por escrito.</div>
                    <div className={styles.topic}>2. Privacidade e Proteção de Dados</div>
                    <div className={styles.text}>2.1. Respeitamos sua privacidade e levamos a proteção de dados a sério. Ao utilizar nosso aplicativo, você concorda com a coleta, uso e armazenamento de informações pessoais, conforme estabelecido em nossa Política de Privacidade. Certifique-se de ler atentamente nossa Política de Privacidade para entender como suas informações são coletadas, usadas e protegidas.</div>
                    <div className={styles.topic}>3. Responsabilidade e Limitações</div>
                    <div className={styles.text}>3.1. O "Storyland" se esforça para fornecer um aplicativo seguro e livre de erros. No entanto, não podemos garantir que o aplicativo estará livre de interrupções, bugs ou falhas técnicas. O uso do aplicativo é de sua responsabilidade e você concorda em utilizá-lo por sua conta e risco.</div>
                    <div className={styles.text}>3.2. Não nos responsabilizamos por quaisquer danos diretos, indiretos, incidentais, consequenciais ou punitivos resultantes do uso ou incapacidade de usar o aplicativo, incluindo perda de dados, lucros ou oportunidades de negócio.</div>
                    <div className={styles.topic}>4. Modificações e Atualizações</div>
                    <div className={styles.text}>4.1. Reservamos o direito de modificar, atualizar ou encerrar o aplicativo a qualquer momento, sem aviso prévio. Essas modificações podem incluir a adição, alteração ou remoção de recursos ou funcionalidades.</div>
                    <div className={styles.text}>4.2. Recomendamos que você mantenha o aplicativo atualizado para desfrutar dos recursos mais recentes e corrigir eventuais problemas de desempenho ou segurança.</div>
                    <div className={styles.topic}>5. Contato</div>
                    <div className={styles.text}>5.1. Se tiver dúvidas, preocupações ou comentários sobre o "Storyland" ou estes Termos, entre em contato conosco através das informações fornecidas em nosso site.</div>
                </div>
                <br />
                <br />
                <div className={styles.content}>
                    <div className={styles.topic}>Política de Privacidade - Storyland</div>
                    <div className={styles.text}>Última atualização: 22/05/2023</div>
                    <div className={styles.text}>Agradecemos por utilizar o aplicativo "Storyland" (doravante referido como "aplicativo"). Nesta política de privacidade, explicamos como coletamos, usamos, armazenamos e protegemos as informações pessoais dos usuários. Ao utilizar nosso aplicativo, você concorda com esta política de privacidade.</div>
                    <div className={styles.topic}>1. Informações coletadas</div>
                    <div className={styles.text}>1.1. Informações fornecidas pelos usuários: Durante o uso do aplicativo, podemos coletar as seguintes informações fornecidas pelos usuários:</div>
                    <div className={styles.text}>Informações de registro: nome, idade e outras informações necessárias para criar uma conta de usuário.</div>
                    <div className={styles.text}>Informações de contato: endereço de e-mail e informações de contato adicionais fornecidas pelos usuários para receber atualizações e comunicações relacionadas ao aplicativo.</div>
                    <div className={styles.text}>Informações de pagamento: caso ocorram transações de compra dentro do aplicativo, coletaremos as informações necessárias para processar o pagamento.</div>
                    <div className={styles.text}>1.2. Informações coletadas automaticamente: Podemos coletar automaticamente determinadas informações sobre o uso do aplicativo, incluindo:</div>
                    <div className={styles.text}>Informações de dispositivo: modelo do dispositivo, sistema operacional, versão do aplicativo e identificadores exclusivos do dispositivo.</div>
                    <div className={styles.text}>Informações de uso: dados de uso, como páginas visitadas, tempo gasto no aplicativo, interações com o conteúdo e outras estatísticas relacionadas ao uso do aplicativo.</div>
                    <div className={styles.topic}>2. Uso das informações</div>
                    <div className={styles.text}>2.1. Utilizamos as informações coletadas para os seguintes propósitos:</div>
                    <div className={styles.text}>Fornecer e melhorar o aplicativo, incluindo personalização de conteúdo e recursos.</div>
                    <div className={styles.text}>Processar transações e fornecer suporte relacionado a pagamentos.</div>
                    <div className={styles.text}>Enviar atualizações, notificações e comunicações relacionadas ao aplicativo.</div>
                    <div className={styles.text}>Responder a perguntas, solicitações de suporte e outras consultas dos usuários.</div>
                    <div className={styles.text}>Analisar o uso do aplicativo, realizar pesquisas e coletar informações estatísticas para melhorar nossa oferta.</div>
                    <div className={styles.topic}>3. Compartilhamento de informações</div>
                    <div className={styles.text}>3.1. Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes circunstâncias:</div>
                    <div className={styles.text}>Com sua autorização explícita.</div>
                    <div className={styles.text}>Para cumprir requisitos legais, regulatórios ou governamentais.</div>
                    <div className={styles.text}>Com provedores de serviços terceirizados que nos auxiliam na operação e manutenção do aplicativo.</div>
                    <div className={styles.text}>Em caso de fusão, aquisição ou venda de ativos, onde as informações do usuário possam ser transferidas como parte da transação.</div>
                    <div className={styles.topic}>4. Proteção de informações</div>
                    <div className={styles.text}>4.1. Empregamos medidas de segurança adequadas para proteger as informações pessoais dos usuários contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela Internet ou armazenamento eletrônico é completamente seguro, e não podemos garantir a segurança absoluta das informações.</div>
                    <div className={styles.topic}>5. Privacidade das crianças</div>
                    <div className={styles.text}>5.1. O aplicativo é destinado a crianças e sua privacidade é uma prioridade para nós. Não coletamos intencionalmente informações pessoais de crianças menores de 13 anos sem o consentimento verificável dos pais ou responsáveis. Caso descubramos que coletamos informações pessoais de uma criança sem consentimento parental, tomaremos medidas para excluir essas informações o mais rápido possível.</div>
                    <div className={styles.topic}>6. Direitos dos usuários</div>
                    <div className={styles.text}>6.1. Os usuários têm direito a acessar, corrigir, atualizar ou excluir suas informações pessoais. Para exercer esses direitos, entre em contato conosco através das informações fornecidas no final desta política.</div>
                    <div className={styles.topic}>7. Alterações nesta política</div>
                    <div className={styles.text}>7.1. Podemos atualizar esta política de privacidade periodicamente. A versão mais recente estará disponível no aplicativo. Notificaremos os usuários sobre quaisquer alterações significativas enviando um aviso para o endereço de e-mail fornecido ou por meio de um aviso dentro do aplicativo.</div>
                    <div className={styles.topic}>8. Contato</div>
                    <div className={styles.text}>8.1. Se tiver dúvidas, preocupações ou comentários sobre esta política de privacidade, entre em contato conosco através das informações fornecidas em nosso site.</div>
                </div>
                <div className={styles.content}>
                    <div className={styles.topic}>Exclusão de Conta</div>
                    <div className={styles.text}>Última atualização: 03/12/2024</div>
                    <div className={styles.text}>
                        1. Os usuários têm o direito de solicitar a exclusão de sua conta a qualquer momento. Para isso, envie um e-mail para <a href="mailto:contato@tosostudio.com.br">contato@tosostudio.com.br</a> com o endereço de e-mail associado à conta que deseja excluir.
                    </div>
                    <div className={styles.text}>
                        2. Após o recebimento da solicitação, tomaremos as medidas necessárias para processar a exclusão da conta dentro de um prazo de até 30 dias úteis. Todas as informações pessoais associadas à conta serão removidas conforme descrito em nossa Política de Privacidade.
                    </div>
                    <div className={styles.text}>
                        3. Note que algumas informações podem ser retidas por razões legais, de segurança ou para cumprir com nossas obrigações regulatórias, conforme permitido pela lei aplicável.
                    </div>
                </div>

            </div>
        </div>
    )
}
